:root {
  --purple: #6435c9;
  --reach-dialog: 1;
}

/* #f5f6f8 */
/* #5a6169 */

body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  min-height: 100%;
}

#root {
  display: flex;
}
/* reach router seems to need this rule... */
/* #root > div {
  min-height: 100%;
} */

/* #root {
  display: flex;
} */
#root > div {
  display: flex;
  flex-grow: 1;
}

.ck-editor__editable,
.ck-editor__editable.ck-focused {
  border: 0 !important;
  box-shadow: initial !important;
}

.layout {
  display: flex;
  flex-grow: 1;
}
.layout .ui.vertical.menu .item > i.icon.left {
  float: none;
  margin: 0em 0.35714286em 0em 0em;
}
.layout .sidebar {
  /* padding: 24px 32px; */
  /* min-width: 272px; */
  max-width: 320px;
  height: 100%;
  flex: 3 0 0;
  background-color: var(--purple);
  z-index: 10;
  box-shadow: 0 0.125rem 9.375rem rgba(90,97,105,.1), 
              0 0.25rem 0.5rem rgba(90,97,105,.12),
              0 0.9375rem 1.375rem rgba(90,97,105,.1), 
              0 0.4375rem 2.1875rem rgba(165,182,201,.1);
}

.primary {
  flex: 1 0 0;
  background: #f5f6f8;
  /* flex-grow: 1; */
  /* flex: auto; */
}
/* .primary.dashboard {
  background-color: var(--purple);
} */

.form-teaser b {
  margin-left: 6px;
}
.form-teaser b:first-child {
  margin-left: 0;
}

main {
  margin: 32px;
}

footer {
  padding: 32px;
  background-color: #000;
  color: #e3e3e3;
}

.site-selector-container {
  display: inline-block;
  margin: 0 24px;
}
.site-selector-container label {
  font-size: small;
}

.site-selector-container select {
  border: 0;
  background: none;
}

.ui.cheating.dropdown {
  display: none;
}

/* @media (min-width: 721px) { */
@media (min-width: 841px) {
  .primary:not(.management) header {
    display: none;
  }
}
@media (max-width: 840px) {
/* @media (max-width: 720px) { */
  .layout .sidebar {
    display: none;
    width: 100%;
  }
  .ui.cheating.dropdown {
    display: inline-block;
  }
}

.primary-header {
  display: flex;
  margin: 16px 32px 32px;
  justify-content: space-between;
}
.primary-header .user-ident span {
  margin-left: 1em;
  margin-right: 1em;
}
.primary-header .user-ident a {
  color: rgba(0, 0, 0, 0.87);
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #21ba45 !important
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21ba45 !important;
}


.drawer {
  background: #fff;
  padding: 16px;

  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  height: 100%;
  box-shadow: -2px 0 8px rgba(0,0,0,0.15);
  
  z-index: 1001;

  transform: translateX(100%);

  display: flex;
  flex-direction: column;
}

.drawer.slide-in {
  animation-name: slide-in;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

.drawer-header {
  /* margin-bottom: 32px; */
  margin-bottom: 16px;
}
.drawer-content {
  overflow-x: hidden;
  overflow-y: scroll;
  /* height: calc(100% - 40px); */
  flex-grow: 1;
}
/* .drawer.active {
  transform: translateX(0);
} */


.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.overflow-hidden {
  overflow: hidden;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
}
