body.login {
  background-color: #6435c9;
}
body.login #root > div > .grid {
  height: 100%;
  flex-grow: 1;
}
body.login .image {
  margin-top: -100px;
}
body.login .column {
  max-width: 400px;
}
